<template>
  <div class="blog-post-page">
    <div class="container container--small" v-if="postData">
      <base-image
        :src="mainImage.url"
        :alt="mainImage.alt"
        class="blog-post-page__image"
      />

      <header class="blog-post-page-page__header">
        <ul class="blog-post-page__header-list" v-if="categories && categories.length">
          <li
            class="blog-post-page__header-category"
            v-for="category in categories"
            :key="category.id"
          >
            <button
              class="blog-post-page__header-button"
              :title="displayCategory(category.category.slug)"
              :aria-label="displayCategory(category.category.slug)"
              @click="filterByCategory(category)"
            >
              {{ displayCategory(category.category.slug) }}
            </button>
          </li>
        </ul>

        <span class="blog-post-page__header-date">
          {{ filteredDate }}
        </span>
      </header>

      <h1 class="blog-post-page__title">
        {{ title }}
      </h1>

      <prismic-rich-text
        :field="content"
        class="blog-post-page__content prismic"
      />

      <footer class="blog-post-page__bottom">
        <blog-author class="blog-post-page__bottom-author"/>
        <blog-share class="blog-post-page__bottom-share" :title="title"/>
      </footer>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'BlogPostPage',
  metaInfo() {
    return {
      title: `Edunails - ${this.title}`,
      titleTemplate: null,
    };
  },
  data: () => ({
    postData: null,
  }),
  methods: {
    async fetchPost() {
      this.$store.dispatch('ui/setPreloader', true);

      try {
        const response = await this.$prismic.client.getByUID('blog_post', this.pageSlug);
        this.postData = response;
      } catch (err) {
        console.log(err);
        this.$router.push('/404');
      } finally {
        this.$store.dispatch('ui/setPreloader', false);
      }
    },
    displayCategory(categorySlug) {
      return categorySlug ? categorySlug.replace(/-/g, ' ') : 'Default';
    },
  },
  computed: {
    filteredDate() {
      return dayjs(this.postData.last_publication_date).format('MMM DD, YYYY');
    },
    pageSlug() {
      return this.$route.params.slug;
    },
    mainImage() {
      return this.postData.data.main_image;
    },
    title() {
      return this.postData.data.title[0].text;
    },
    content() {
      return this.postData.data.content;
    },
    categories() {
      return this.postData.data.categories.filter((category) => category.category.id);
    },
  },
  created() {
    this.fetchPost();
  },
};
</script>

<style lang="scss" src="./BlogPostPage.scss"/>
